/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { DataClassification } from '../models/DataClassification';

export class DataClassificationsUpdateRequest {
  'resourceIds'?: Array<string>;
  'dataClassifications'?: Array<DataClassification>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "resourceIds",
      "baseName": "resourceIds",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "dataClassifications",
      "baseName": "dataClassifications",
      "type": "Array<DataClassification>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return DataClassificationsUpdateRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

