/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export enum DataClassification {
  Unspecified = 'UNSPECIFIED',
  Pii = 'PII',
  Phi = 'PHI',
  Fi = 'FI'
}
