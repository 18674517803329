/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AuditLog } from '../models/AuditLog';

export class ListAuditLogResponse {
  'logs'?: Array<AuditLog>;
  'nextToken'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "logs",
      "baseName": "logs",
      "type": "Array<AuditLog>",
      "format": ""
    },
    {
      "name": "nextToken",
      "baseName": "nextToken",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListAuditLogResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

