export * from '../models/Account';
export * from '../models/AccountIdFilters';
export * from '../models/AccountUpdateInput';
export * from '../models/AccountsResponse';
export * from '../models/ActionFilters';
export * from '../models/AppFilters';
export * from '../models/ApplicationOverride';
export * from '../models/ApplicationPath';
export * from '../models/AtlasProject';
export * from '../models/AtlasProjects';
export * from '../models/AuditLog';
export * from '../models/AuditLogEntitiesInner';
export * from '../models/AuditLogFilterConditions';
export * from '../models/AuthResponse';
export * from '../models/AzureConsentUrlResponse';
export * from '../models/AzureOnboardingRequest';
export * from '../models/AzureOnboardingStatus';
export * from '../models/AzureOnboardingStatusResponse';
export * from '../models/AzureSubscription';
export * from '../models/AzureSubscriptions';
export * from '../models/BackedUpByResourceType';
export * from '../models/BackedUpByResourceTypeDataInner';
export * from '../models/BackedUpStorageDateHistogram';
export * from '../models/BackupControlViolations';
export * from '../models/BackupFromDatesInput';
export * from '../models/BackupFromDatesResponse';
export * from '../models/BackupFromSnapshotsInput';
export * from '../models/BackupJobResponse';
export * from '../models/BackupPoliciesFilters';
export * from '../models/BackupPolicy';
export * from '../models/BackupRegionsFilters';
export * from '../models/BackupSchedule';
export * from '../models/BackupStatus';
export * from '../models/BackupStatusFilters';
export * from '../models/BackupVault';
export * from '../models/BatchApplicationOverrides';
export * from '../models/BatchOperationResponse';
export * from '../models/BooleanFilters';
export * from '../models/ColumnMetadata';
export * from '../models/Condition';
export * from '../models/Control';
export * from '../models/ControlRules';
export * from '../models/ControlViolation';
export * from '../models/ControlViolations';
export * from '../models/ControlViolationsPerSeverity';
export * from '../models/ControlViolationsPerSeverityViolatedControlsInner';
export * from '../models/ControlsFilters';
export * from '../models/ConvertVolumeInput';
export * from '../models/ConvertVolumeToSnapshotInput';
export * from '../models/CreateAccountInput';
export * from '../models/CreateBackupJobInput';
export * from '../models/CreateBackupVaultInput';
export * from '../models/CreatePolicyInput';
export * from '../models/CreatePolicyScheduleInput';
export * from '../models/CreateProjectInput';
export * from '../models/CreateRoleInput';
export * from '../models/CreateSamlIdentityProviderConfigInput';
export * from '../models/CreateScanJobInput';
export * from '../models/CreateServiceAccountRequest';
export * from '../models/CreateServiceAccountResponse';
export * from '../models/CreateUserInput';
export * from '../models/DBResourceMetadata';
export * from '../models/DBServerRecord';
export * from '../models/DBSnapshotMetadata';
export * from '../models/DBSnapshotMetadataList';
export * from '../models/DailyStorageSummary';
export * from '../models/DataClassesFilters';
export * from '../models/DataClassification';
export * from '../models/DataClassificationEntity';
export * from '../models/DataClassificationsUpdateRequest';
export * from '../models/DataClassificationsUpdateResponse';
export * from '../models/DatabaseProperties';
export * from '../models/DatabaseRecord';
export * from '../models/DiscoveryInvokeParams';
export * from '../models/DiscoveryStatusResponse';
export * from '../models/DriftProtectionPolicy';
export * from '../models/DriftProtectionSummary';
export * from '../models/DriftProtectionTimePoint';
export * from '../models/EncryptionKey';
export * from '../models/EncryptionKeys';
export * from '../models/Environment';
export * from '../models/EnvironmentFilters';
export * from '../models/EonAccount';
export * from '../models/FileExplorerResponse';
export * from '../models/FileExplorerResult';
export * from '../models/FilePath';
export * from '../models/FileSearchRecord';
export * from '../models/FileSnapshot';
export * from '../models/GenericSnapshotData';
export * from '../models/GenericSnapshotsPerVolume';
export * from '../models/GetDailyStorageSummaries';
export * from '../models/GetJobResponse';
export * from '../models/GroupToRoleMapping';
export * from '../models/IDs';
export * from '../models/IdFilters';
export * from '../models/Identifier';
export * from '../models/InitAuthInput';
export * from '../models/InventoryFilterConditions';
export * from '../models/InventoryResource';
export * from '../models/InventoryVault';
export * from '../models/InventoryVolume';
export * from '../models/Job';
export * from '../models/JobType';
export * from '../models/JobsFilterConditions';
export * from '../models/JobsFilterConditionsUserId';
export * from '../models/ListAccountsResponse';
export * from '../models/ListApplicationOverrideResponse';
export * from '../models/ListAuditLogRequest';
export * from '../models/ListAuditLogRequestSorts';
export * from '../models/ListAuditLogResponse';
export * from '../models/ListAuditLogsResponse';
export * from '../models/ListBackupPolicyResponse';
export * from '../models/ListBackupVaultResponse';
export * from '../models/ListControlViolationsResponse';
export * from '../models/ListControlsResponse';
export * from '../models/ListDataClassificationEntitiesResponse';
export * from '../models/ListGenericSnapshotsResponse';
export * from '../models/ListInventoryAppsResponse';
export * from '../models/ListInventoryBackupRegionsResponse';
export * from '../models/ListInventoryEnvironmentsResponse';
export * from '../models/ListInventoryItemSnapshotsResponse';
export * from '../models/ListInventoryNetworksResponse';
export * from '../models/ListInventoryRequest';
export * from '../models/ListInventoryRequestSorts';
export * from '../models/ListInventoryResourceTypesResponse';
export * from '../models/ListInventoryResourcesResponse';
export * from '../models/ListInventorySnapshotsResponse';
export * from '../models/ListInventorySourceRegionsResponse';
export * from '../models/ListInventorySubnetsResponse';
export * from '../models/ListJobs200Response';
export * from '../models/ListJobsRequest';
export * from '../models/ListJobsRequestSorts';
export * from '../models/ListJobsResponse';
export * from '../models/ListProjectsResponse';
export * from '../models/ListResourcesResponse';
export * from '../models/ListServiceAccountsResponse';
export * from '../models/ListUsersResponse';
export * from '../models/MaxRetentionRule';
export * from '../models/Message';
export * from '../models/MinRetentionRule';
export * from '../models/ModelError';
export * from '../models/NumberOfCopiesRule';
export * from '../models/OperationIdFilters';
export * from '../models/OverrideEnvironmentInput';
export * from '../models/Pagination';
export * from '../models/PathSnapshotsRequest';
export * from '../models/PathSnapshotsResponse';
export * from '../models/Preferences';
export * from '../models/Project';
export * from '../models/QueryDBResponse';
export * from '../models/QueryDBResultResponse';
export * from '../models/QueryDBStatusResponse';
export * from '../models/QueryParams';
export * from '../models/RegisterInput';
export * from '../models/RegisterInputBillingToken';
export * from '../models/RegisterResponse';
export * from '../models/ResourceIdFilters';
export * from '../models/ResourceNameFilters';
export * from '../models/ResourceType';
export * from '../models/ResourceTypeFilters';
export * from '../models/RestoreAccountRegions';
export * from '../models/RestoreAtlasClusterInput';
export * from '../models/RestoreAvailabilityZones';
export * from '../models/RestoreBucketInput';
export * from '../models/RestoreDatabaseInput';
export * from '../models/RestoreFilesInput';
export * from '../models/RestoreRdsSubnetGroups';
export * from '../models/RestoreRdsSubnetGroupsRdsSubnetGroupsInner';
export * from '../models/RestoreSecurityGroups';
export * from '../models/RestoreSecurityGroupsSecurityGroupsInner';
export * from '../models/RestoreVolumeInput';
export * from '../models/RestoreVolumeInputV1';
export * from '../models/RestoreVolumeInputVolumeSettings';
export * from '../models/Role';
export * from '../models/RolesResponse';
export * from '../models/S3Bucket';
export * from '../models/S3Buckets';
export * from '../models/SamlIdentityProvider';
export * from '../models/SamlIdentityProviderConfigsResponse';
export * from '../models/SchemaRecord';
export * from '../models/SearchDBResponse';
export * from '../models/SearchFilesResponse';
export * from '../models/SearchInput';
export * from '../models/ServiceAccount';
export * from '../models/ServiceAccountCredentials';
export * from '../models/ServiceProviderDetails';
export * from '../models/Severity';
export * from '../models/SeverityFilters';
export * from '../models/Snapshot';
export * from '../models/SnapshotProperties';
export * from '../models/SnapshotPropertiesVolumePropertiesInner';
export * from '../models/SortOrder';
export * from '../models/SourceRegionFilters';
export * from '../models/SourceStorageBackupStatus';
export * from '../models/SsoIntegrationSettings';
export * from '../models/StatusCodeFilters';
export * from '../models/StatusJobFilters';
export * from '../models/SubnetFilters';
export * from '../models/TableRecord';
export * from '../models/TokenResponse';
export * from '../models/UnifiedDBRecord';
export * from '../models/UnifiedDBServerRecord';
export * from '../models/UnifiedDatabaseRecord';
export * from '../models/UnifiedSchemaRecord';
export * from '../models/UnifiedTableRecord';
export * from '../models/UpdateAccountInput';
export * from '../models/UpdateBackupVaultNameInput';
export * from '../models/UpdateControlViolationInput';
export * from '../models/UpdateDataClassificationsRequest';
export * from '../models/UpdateEnvironmentOverrideInput';
export * from '../models/UpdateResourceExclusionInput';
export * from '../models/UpdateRoleInput';
export * from '../models/UpdateSamlIdentityProviderInput';
export * from '../models/UpdateServiceAccountRequest';
export * from '../models/UpdateUserInput';
export * from '../models/UpdateViewerRoleRequest';
export * from '../models/User';
export * from '../models/UserIdFilters';
export * from '../models/VaultFilters';
export * from '../models/Viewer';
export * from '../models/VisualizationHistogramSeries';
export * from '../models/VisualizationHistogramTimePoint';
export * from '../models/VisualizationLabel';
export * from '../models/VolumeSettings';
export * from '../models/VpcPropertyFilters';

import { Account , AccountCloudEnum        } from '../models/Account';
import { AccountIdFilters } from '../models/AccountIdFilters';
import { AccountUpdateInput , AccountUpdateInputCloudEnum        } from '../models/AccountUpdateInput';
import { AccountsResponse } from '../models/AccountsResponse';
import { ActionFilters } from '../models/ActionFilters';
import { AppFilters } from '../models/AppFilters';
import { ApplicationOverride } from '../models/ApplicationOverride';
import { ApplicationPath } from '../models/ApplicationPath';
import { AtlasProject } from '../models/AtlasProject';
import { AtlasProjects } from '../models/AtlasProjects';
import { AuditLog } from '../models/AuditLog';
import { AuditLogEntitiesInner } from '../models/AuditLogEntitiesInner';
import { AuditLogFilterConditions } from '../models/AuditLogFilterConditions';
import { AuthResponse } from '../models/AuthResponse';
import { AzureConsentUrlResponse } from '../models/AzureConsentUrlResponse';
import { AzureOnboardingRequest } from '../models/AzureOnboardingRequest';
import { AzureOnboardingStatus } from '../models/AzureOnboardingStatus';
import { AzureOnboardingStatusResponse } from '../models/AzureOnboardingStatusResponse';
import { AzureSubscription } from '../models/AzureSubscription';
import { AzureSubscriptions } from '../models/AzureSubscriptions';
import { BackedUpByResourceType } from '../models/BackedUpByResourceType';
import { BackedUpByResourceTypeDataInner } from '../models/BackedUpByResourceTypeDataInner';
import { BackedUpStorageDateHistogram } from '../models/BackedUpStorageDateHistogram';
import { BackupControlViolations } from '../models/BackupControlViolations';
import { BackupFromDatesInput } from '../models/BackupFromDatesInput';
import { BackupFromDatesResponse } from '../models/BackupFromDatesResponse';
import { BackupFromSnapshotsInput } from '../models/BackupFromSnapshotsInput';
import { BackupJobResponse } from '../models/BackupJobResponse';
import { BackupPoliciesFilters } from '../models/BackupPoliciesFilters';
import { BackupPolicy } from '../models/BackupPolicy';
import { BackupRegionsFilters } from '../models/BackupRegionsFilters';
import { BackupSchedule } from '../models/BackupSchedule';
import { BackupStatus } from '../models/BackupStatus';
import { BackupStatusFilters } from '../models/BackupStatusFilters';
import { BackupVault } from '../models/BackupVault';
import { BatchApplicationOverrides } from '../models/BatchApplicationOverrides';
import { BatchOperationResponse } from '../models/BatchOperationResponse';
import { BooleanFilters } from '../models/BooleanFilters';
import { ColumnMetadata } from '../models/ColumnMetadata';
import { Condition } from '../models/Condition';
import { Control      } from '../models/Control';
import { ControlRules } from '../models/ControlRules';
import { ControlViolation } from '../models/ControlViolation';
import { ControlViolations } from '../models/ControlViolations';
import { ControlViolationsPerSeverity } from '../models/ControlViolationsPerSeverity';
import { ControlViolationsPerSeverityViolatedControlsInner } from '../models/ControlViolationsPerSeverityViolatedControlsInner';
import { ControlsFilters } from '../models/ControlsFilters';
import { ConvertVolumeInput } from '../models/ConvertVolumeInput';
import { ConvertVolumeToSnapshotInput } from '../models/ConvertVolumeToSnapshotInput';
import { CreateAccountInput, CreateAccountInputCloudProviderEnum       } from '../models/CreateAccountInput';
import { CreateBackupJobInput } from '../models/CreateBackupJobInput';
import { CreateBackupVaultInput } from '../models/CreateBackupVaultInput';
import { CreatePolicyInput } from '../models/CreatePolicyInput';
import { CreatePolicyScheduleInput } from '../models/CreatePolicyScheduleInput';
import { CreateProjectInput } from '../models/CreateProjectInput';
import { CreateRoleInput } from '../models/CreateRoleInput';
import { CreateSamlIdentityProviderConfigInput } from '../models/CreateSamlIdentityProviderConfigInput';
import { CreateScanJobInput } from '../models/CreateScanJobInput';
import { CreateServiceAccountRequest } from '../models/CreateServiceAccountRequest';
import { CreateServiceAccountResponse } from '../models/CreateServiceAccountResponse';
import { CreateUserInput } from '../models/CreateUserInput';
import { DBResourceMetadata } from '../models/DBResourceMetadata';
import { DBServerRecord } from '../models/DBServerRecord';
import { DBSnapshotMetadata } from '../models/DBSnapshotMetadata';
import { DBSnapshotMetadataList } from '../models/DBSnapshotMetadataList';
import { DailyStorageSummary } from '../models/DailyStorageSummary';
import { DataClassesFilters } from '../models/DataClassesFilters';
import { DataClassification } from '../models/DataClassification';
import { DataClassificationEntity } from '../models/DataClassificationEntity';
import { DataClassificationsUpdateRequest } from '../models/DataClassificationsUpdateRequest';
import { DataClassificationsUpdateResponse } from '../models/DataClassificationsUpdateResponse';
import { DatabaseProperties } from '../models/DatabaseProperties';
import { DatabaseRecord } from '../models/DatabaseRecord';
import { DiscoveryInvokeParams } from '../models/DiscoveryInvokeParams';
import { DiscoveryStatusResponse } from '../models/DiscoveryStatusResponse';
import { DriftProtectionPolicy } from '../models/DriftProtectionPolicy';
import { DriftProtectionSummary } from '../models/DriftProtectionSummary';
import { DriftProtectionTimePoint } from '../models/DriftProtectionTimePoint';
import { EncryptionKey } from '../models/EncryptionKey';
import { EncryptionKeys } from '../models/EncryptionKeys';
import { Environment } from '../models/Environment';
import { EnvironmentFilters } from '../models/EnvironmentFilters';
import { EonAccount } from '../models/EonAccount';
import { FileExplorerResponse } from '../models/FileExplorerResponse';
import { FileExplorerResult } from '../models/FileExplorerResult';
import { FilePath } from '../models/FilePath';
import { FileSearchRecord } from '../models/FileSearchRecord';
import { FileSnapshot } from '../models/FileSnapshot';
import { GenericSnapshotData } from '../models/GenericSnapshotData';
import { GenericSnapshotsPerVolume } from '../models/GenericSnapshotsPerVolume';
import { GetDailyStorageSummaries } from '../models/GetDailyStorageSummaries';
import { GetJobResponse } from '../models/GetJobResponse';
import { GroupToRoleMapping } from '../models/GroupToRoleMapping';
import { IDs } from '../models/IDs';
import { IdFilters } from '../models/IdFilters';
import { Identifier } from '../models/Identifier';
import { InitAuthInput } from '../models/InitAuthInput';
import { InventoryFilterConditions } from '../models/InventoryFilterConditions';
import { InventoryResource    , InventoryResourceResourceTypeEnum  , InventoryResourceCloudProviderEnum                                } from '../models/InventoryResource';
import { InventoryVault } from '../models/InventoryVault';
import { InventoryVolume } from '../models/InventoryVolume';
import { Job } from '../models/Job';
import { JobType } from '../models/JobType';
import { JobsFilterConditions } from '../models/JobsFilterConditions';
import { JobsFilterConditionsUserId } from '../models/JobsFilterConditionsUserId';
import { ListAccountsResponse } from '../models/ListAccountsResponse';
import { ListApplicationOverrideResponse } from '../models/ListApplicationOverrideResponse';
import { ListAuditLogRequest } from '../models/ListAuditLogRequest';
import { ListAuditLogRequestSorts      } from '../models/ListAuditLogRequestSorts';
import { ListAuditLogResponse } from '../models/ListAuditLogResponse';
import { ListAuditLogsResponse } from '../models/ListAuditLogsResponse';
import { ListBackupPolicyResponse } from '../models/ListBackupPolicyResponse';
import { ListBackupVaultResponse } from '../models/ListBackupVaultResponse';
import { ListControlViolationsResponse } from '../models/ListControlViolationsResponse';
import { ListControlsResponse } from '../models/ListControlsResponse';
import { ListDataClassificationEntitiesResponse } from '../models/ListDataClassificationEntitiesResponse';
import { ListGenericSnapshotsResponse } from '../models/ListGenericSnapshotsResponse';
import { ListInventoryAppsResponse } from '../models/ListInventoryAppsResponse';
import { ListInventoryBackupRegionsResponse } from '../models/ListInventoryBackupRegionsResponse';
import { ListInventoryEnvironmentsResponse } from '../models/ListInventoryEnvironmentsResponse';
import { ListInventoryItemSnapshotsResponse } from '../models/ListInventoryItemSnapshotsResponse';
import { ListInventoryNetworksResponse } from '../models/ListInventoryNetworksResponse';
import { ListInventoryRequest } from '../models/ListInventoryRequest';
import { ListInventoryRequestSorts            } from '../models/ListInventoryRequestSorts';
import { ListInventoryResourceTypesResponse } from '../models/ListInventoryResourceTypesResponse';
import { ListInventoryResourcesResponse } from '../models/ListInventoryResourcesResponse';
import { ListInventorySnapshotsResponse } from '../models/ListInventorySnapshotsResponse';
import { ListInventorySourceRegionsResponse } from '../models/ListInventorySourceRegionsResponse';
import { ListInventorySubnetsResponse } from '../models/ListInventorySubnetsResponse';
import { ListJobs200Response } from '../models/ListJobs200Response';
import { ListJobsRequest     } from '../models/ListJobsRequest';
import { ListJobsRequestSorts        } from '../models/ListJobsRequestSorts';
import { ListJobsResponse } from '../models/ListJobsResponse';
import { ListProjectsResponse } from '../models/ListProjectsResponse';
import { ListResourcesResponse } from '../models/ListResourcesResponse';
import { ListServiceAccountsResponse } from '../models/ListServiceAccountsResponse';
import { ListUsersResponse } from '../models/ListUsersResponse';
import { MaxRetentionRule } from '../models/MaxRetentionRule';
import { Message } from '../models/Message';
import { MinRetentionRule  , MinRetentionRuleFrequencyEnum   } from '../models/MinRetentionRule';
import { ModelError } from '../models/ModelError';
import { NumberOfCopiesRule } from '../models/NumberOfCopiesRule';
import { OperationIdFilters } from '../models/OperationIdFilters';
import { OverrideEnvironmentInput } from '../models/OverrideEnvironmentInput';
import { Pagination } from '../models/Pagination';
import { PathSnapshotsRequest } from '../models/PathSnapshotsRequest';
import { PathSnapshotsResponse } from '../models/PathSnapshotsResponse';
import { Preferences } from '../models/Preferences';
import { Project } from '../models/Project';
import { QueryDBResponse } from '../models/QueryDBResponse';
import { QueryDBResultResponse } from '../models/QueryDBResultResponse';
import { QueryDBStatusResponse , QueryDBStatusResponseStatusEnum   } from '../models/QueryDBStatusResponse';
import { QueryParams } from '../models/QueryParams';
import { RegisterInput } from '../models/RegisterInput';
import { RegisterInputBillingToken } from '../models/RegisterInputBillingToken';
import { RegisterResponse } from '../models/RegisterResponse';
import { ResourceIdFilters } from '../models/ResourceIdFilters';
import { ResourceNameFilters } from '../models/ResourceNameFilters';
import { ResourceType } from '../models/ResourceType';
import { ResourceTypeFilters } from '../models/ResourceTypeFilters';
import { RestoreAccountRegions } from '../models/RestoreAccountRegions';
import { RestoreAtlasClusterInput } from '../models/RestoreAtlasClusterInput';
import { RestoreAvailabilityZones } from '../models/RestoreAvailabilityZones';
import { RestoreBucketInput } from '../models/RestoreBucketInput';
import { RestoreDatabaseInput   , RestoreDatabaseInputRestoreTypeEnum       } from '../models/RestoreDatabaseInput';
import { RestoreFilesInput } from '../models/RestoreFilesInput';
import { RestoreRdsSubnetGroups } from '../models/RestoreRdsSubnetGroups';
import { RestoreRdsSubnetGroupsRdsSubnetGroupsInner } from '../models/RestoreRdsSubnetGroupsRdsSubnetGroupsInner';
import { RestoreSecurityGroups } from '../models/RestoreSecurityGroups';
import { RestoreSecurityGroupsSecurityGroupsInner } from '../models/RestoreSecurityGroupsSecurityGroupsInner';
import { RestoreVolumeInput } from '../models/RestoreVolumeInput';
import { RestoreVolumeInputV1 } from '../models/RestoreVolumeInputV1';
import { RestoreVolumeInputVolumeSettings } from '../models/RestoreVolumeInputVolumeSettings';
import { Role } from '../models/Role';
import { RolesResponse } from '../models/RolesResponse';
import { S3Bucket } from '../models/S3Bucket';
import { S3Buckets } from '../models/S3Buckets';
import { SamlIdentityProvider } from '../models/SamlIdentityProvider';
import { SamlIdentityProviderConfigsResponse } from '../models/SamlIdentityProviderConfigsResponse';
import { SchemaRecord } from '../models/SchemaRecord';
import { SearchDBResponse } from '../models/SearchDBResponse';
import { SearchFilesResponse } from '../models/SearchFilesResponse';
import { SearchInput } from '../models/SearchInput';
import { ServiceAccount } from '../models/ServiceAccount';
import { ServiceAccountCredentials } from '../models/ServiceAccountCredentials';
import { ServiceProviderDetails } from '../models/ServiceProviderDetails';
import { Severity } from '../models/Severity';
import { SeverityFilters } from '../models/SeverityFilters';
import { Snapshot } from '../models/Snapshot';
import { SnapshotProperties } from '../models/SnapshotProperties';
import { SnapshotPropertiesVolumePropertiesInner } from '../models/SnapshotPropertiesVolumePropertiesInner';
import { SortOrder } from '../models/SortOrder';
import { SourceRegionFilters } from '../models/SourceRegionFilters';
import { SourceStorageBackupStatus } from '../models/SourceStorageBackupStatus';
import { SsoIntegrationSettings } from '../models/SsoIntegrationSettings';
import { StatusCodeFilters } from '../models/StatusCodeFilters';
import { StatusJobFilters } from '../models/StatusJobFilters';
import { SubnetFilters } from '../models/SubnetFilters';
import { TableRecord } from '../models/TableRecord';
import { TokenResponse } from '../models/TokenResponse';
import { UnifiedDBRecord } from '../models/UnifiedDBRecord';
import { UnifiedDBServerRecord } from '../models/UnifiedDBServerRecord';
import { UnifiedDatabaseRecord } from '../models/UnifiedDatabaseRecord';
import { UnifiedSchemaRecord } from '../models/UnifiedSchemaRecord';
import { UnifiedTableRecord } from '../models/UnifiedTableRecord';
import { UpdateAccountInput } from '../models/UpdateAccountInput';
import { UpdateBackupVaultNameInput } from '../models/UpdateBackupVaultNameInput';
import { UpdateControlViolationInput } from '../models/UpdateControlViolationInput';
import { UpdateDataClassificationsRequest } from '../models/UpdateDataClassificationsRequest';
import { UpdateEnvironmentOverrideInput } from '../models/UpdateEnvironmentOverrideInput';
import { UpdateResourceExclusionInput } from '../models/UpdateResourceExclusionInput';
import { UpdateRoleInput } from '../models/UpdateRoleInput';
import { UpdateSamlIdentityProviderInput } from '../models/UpdateSamlIdentityProviderInput';
import { UpdateServiceAccountRequest } from '../models/UpdateServiceAccountRequest';
import { UpdateUserInput } from '../models/UpdateUserInput';
import { UpdateViewerRoleRequest } from '../models/UpdateViewerRoleRequest';
import { User } from '../models/User';
import { UserIdFilters } from '../models/UserIdFilters';
import { VaultFilters } from '../models/VaultFilters';
import { Viewer } from '../models/Viewer';
import { VisualizationHistogramSeries } from '../models/VisualizationHistogramSeries';
import { VisualizationHistogramTimePoint } from '../models/VisualizationHistogramTimePoint';
import { VisualizationLabel } from '../models/VisualizationLabel';
import { VolumeSettings } from '../models/VolumeSettings';
import { VpcPropertyFilters } from '../models/VpcPropertyFilters';

/* tslint:disable:no-unused-variable */
let primitives = [
          "string",
          "boolean",
          "double",
          "integer",
          "long",
          "float",
          "number",
          "any"
         ];

let enumsMap: Set<string> = new Set<string>([
  "AccountCloudEnum",
  "AccountUpdateInputCloudEnum",
  "BackupStatus",
  "CreateAccountInputCloudProviderEnum",
  "DataClassification",
  "Environment",
  "InventoryResourceResourceTypeEnum",
  "InventoryResourceCloudProviderEnum",
  "JobType",
  "MinRetentionRuleFrequencyEnum",
  "QueryDBStatusResponseStatusEnum",
  "ResourceType",
  "RestoreDatabaseInputRestoreTypeEnum",
  "Severity",
  "SortOrder",
]);

let typeMap: {[index: string]: any} = {
  "Account": Account,
  "AccountIdFilters": AccountIdFilters,
  "AccountUpdateInput": AccountUpdateInput,
  "AccountsResponse": AccountsResponse,
  "ActionFilters": ActionFilters,
  "AppFilters": AppFilters,
  "ApplicationOverride": ApplicationOverride,
  "ApplicationPath": ApplicationPath,
  "AtlasProject": AtlasProject,
  "AtlasProjects": AtlasProjects,
  "AuditLog": AuditLog,
  "AuditLogEntitiesInner": AuditLogEntitiesInner,
  "AuditLogFilterConditions": AuditLogFilterConditions,
  "AuthResponse": AuthResponse,
  "AzureConsentUrlResponse": AzureConsentUrlResponse,
  "AzureOnboardingRequest": AzureOnboardingRequest,
  "AzureOnboardingStatus": AzureOnboardingStatus,
  "AzureOnboardingStatusResponse": AzureOnboardingStatusResponse,
  "AzureSubscription": AzureSubscription,
  "AzureSubscriptions": AzureSubscriptions,
  "BackedUpByResourceType": BackedUpByResourceType,
  "BackedUpByResourceTypeDataInner": BackedUpByResourceTypeDataInner,
  "BackedUpStorageDateHistogram": BackedUpStorageDateHistogram,
  "BackupControlViolations": BackupControlViolations,
  "BackupFromDatesInput": BackupFromDatesInput,
  "BackupFromDatesResponse": BackupFromDatesResponse,
  "BackupFromSnapshotsInput": BackupFromSnapshotsInput,
  "BackupJobResponse": BackupJobResponse,
  "BackupPoliciesFilters": BackupPoliciesFilters,
  "BackupPolicy": BackupPolicy,
  "BackupRegionsFilters": BackupRegionsFilters,
  "BackupSchedule": BackupSchedule,
  "BackupStatusFilters": BackupStatusFilters,
  "BackupVault": BackupVault,
  "BatchApplicationOverrides": BatchApplicationOverrides,
  "BatchOperationResponse": BatchOperationResponse,
  "BooleanFilters": BooleanFilters,
  "ColumnMetadata": ColumnMetadata,
  "Condition": Condition,
  "Control": Control,
  "ControlRules": ControlRules,
  "ControlViolation": ControlViolation,
  "ControlViolations": ControlViolations,
  "ControlViolationsPerSeverity": ControlViolationsPerSeverity,
  "ControlViolationsPerSeverityViolatedControlsInner": ControlViolationsPerSeverityViolatedControlsInner,
  "ControlsFilters": ControlsFilters,
  "ConvertVolumeInput": ConvertVolumeInput,
  "ConvertVolumeToSnapshotInput": ConvertVolumeToSnapshotInput,
  "CreateAccountInput": CreateAccountInput,
  "CreateBackupJobInput": CreateBackupJobInput,
  "CreateBackupVaultInput": CreateBackupVaultInput,
  "CreatePolicyInput": CreatePolicyInput,
  "CreatePolicyScheduleInput": CreatePolicyScheduleInput,
  "CreateProjectInput": CreateProjectInput,
  "CreateRoleInput": CreateRoleInput,
  "CreateSamlIdentityProviderConfigInput": CreateSamlIdentityProviderConfigInput,
  "CreateScanJobInput": CreateScanJobInput,
  "CreateServiceAccountRequest": CreateServiceAccountRequest,
  "CreateServiceAccountResponse": CreateServiceAccountResponse,
  "CreateUserInput": CreateUserInput,
  "DBResourceMetadata": DBResourceMetadata,
  "DBServerRecord": DBServerRecord,
  "DBSnapshotMetadata": DBSnapshotMetadata,
  "DBSnapshotMetadataList": DBSnapshotMetadataList,
  "DailyStorageSummary": DailyStorageSummary,
  "DataClassesFilters": DataClassesFilters,
  "DataClassificationEntity": DataClassificationEntity,
  "DataClassificationsUpdateRequest": DataClassificationsUpdateRequest,
  "DataClassificationsUpdateResponse": DataClassificationsUpdateResponse,
  "DatabaseProperties": DatabaseProperties,
  "DatabaseRecord": DatabaseRecord,
  "DiscoveryInvokeParams": DiscoveryInvokeParams,
  "DiscoveryStatusResponse": DiscoveryStatusResponse,
  "DriftProtectionPolicy": DriftProtectionPolicy,
  "DriftProtectionSummary": DriftProtectionSummary,
  "DriftProtectionTimePoint": DriftProtectionTimePoint,
  "EncryptionKey": EncryptionKey,
  "EncryptionKeys": EncryptionKeys,
  "EnvironmentFilters": EnvironmentFilters,
  "EonAccount": EonAccount,
  "FileExplorerResponse": FileExplorerResponse,
  "FileExplorerResult": FileExplorerResult,
  "FilePath": FilePath,
  "FileSearchRecord": FileSearchRecord,
  "FileSnapshot": FileSnapshot,
  "GenericSnapshotData": GenericSnapshotData,
  "GenericSnapshotsPerVolume": GenericSnapshotsPerVolume,
  "GetDailyStorageSummaries": GetDailyStorageSummaries,
  "GetJobResponse": GetJobResponse,
  "GroupToRoleMapping": GroupToRoleMapping,
  "IDs": IDs,
  "IdFilters": IdFilters,
  "Identifier": Identifier,
  "InitAuthInput": InitAuthInput,
  "InventoryFilterConditions": InventoryFilterConditions,
  "InventoryResource": InventoryResource,
  "InventoryVault": InventoryVault,
  "InventoryVolume": InventoryVolume,
  "Job": Job,
  "JobsFilterConditions": JobsFilterConditions,
  "JobsFilterConditionsUserId": JobsFilterConditionsUserId,
  "ListAccountsResponse": ListAccountsResponse,
  "ListApplicationOverrideResponse": ListApplicationOverrideResponse,
  "ListAuditLogRequest": ListAuditLogRequest,
  "ListAuditLogRequestSorts": ListAuditLogRequestSorts,
  "ListAuditLogResponse": ListAuditLogResponse,
  "ListAuditLogsResponse": ListAuditLogsResponse,
  "ListBackupPolicyResponse": ListBackupPolicyResponse,
  "ListBackupVaultResponse": ListBackupVaultResponse,
  "ListControlViolationsResponse": ListControlViolationsResponse,
  "ListControlsResponse": ListControlsResponse,
  "ListDataClassificationEntitiesResponse": ListDataClassificationEntitiesResponse,
  "ListGenericSnapshotsResponse": ListGenericSnapshotsResponse,
  "ListInventoryAppsResponse": ListInventoryAppsResponse,
  "ListInventoryBackupRegionsResponse": ListInventoryBackupRegionsResponse,
  "ListInventoryEnvironmentsResponse": ListInventoryEnvironmentsResponse,
  "ListInventoryItemSnapshotsResponse": ListInventoryItemSnapshotsResponse,
  "ListInventoryNetworksResponse": ListInventoryNetworksResponse,
  "ListInventoryRequest": ListInventoryRequest,
  "ListInventoryRequestSorts": ListInventoryRequestSorts,
  "ListInventoryResourceTypesResponse": ListInventoryResourceTypesResponse,
  "ListInventoryResourcesResponse": ListInventoryResourcesResponse,
  "ListInventorySnapshotsResponse": ListInventorySnapshotsResponse,
  "ListInventorySourceRegionsResponse": ListInventorySourceRegionsResponse,
  "ListInventorySubnetsResponse": ListInventorySubnetsResponse,
  "ListJobs200Response": ListJobs200Response,
  "ListJobsRequest": ListJobsRequest,
  "ListJobsRequestSorts": ListJobsRequestSorts,
  "ListJobsResponse": ListJobsResponse,
  "ListProjectsResponse": ListProjectsResponse,
  "ListResourcesResponse": ListResourcesResponse,
  "ListServiceAccountsResponse": ListServiceAccountsResponse,
  "ListUsersResponse": ListUsersResponse,
  "MaxRetentionRule": MaxRetentionRule,
  "Message": Message,
  "MinRetentionRule": MinRetentionRule,
  "ModelError": ModelError,
  "NumberOfCopiesRule": NumberOfCopiesRule,
  "OperationIdFilters": OperationIdFilters,
  "OverrideEnvironmentInput": OverrideEnvironmentInput,
  "Pagination": Pagination,
  "PathSnapshotsRequest": PathSnapshotsRequest,
  "PathSnapshotsResponse": PathSnapshotsResponse,
  "Preferences": Preferences,
  "Project": Project,
  "QueryDBResponse": QueryDBResponse,
  "QueryDBResultResponse": QueryDBResultResponse,
  "QueryDBStatusResponse": QueryDBStatusResponse,
  "QueryParams": QueryParams,
  "RegisterInput": RegisterInput,
  "RegisterInputBillingToken": RegisterInputBillingToken,
  "RegisterResponse": RegisterResponse,
  "ResourceIdFilters": ResourceIdFilters,
  "ResourceNameFilters": ResourceNameFilters,
  "ResourceTypeFilters": ResourceTypeFilters,
  "RestoreAccountRegions": RestoreAccountRegions,
  "RestoreAtlasClusterInput": RestoreAtlasClusterInput,
  "RestoreAvailabilityZones": RestoreAvailabilityZones,
  "RestoreBucketInput": RestoreBucketInput,
  "RestoreDatabaseInput": RestoreDatabaseInput,
  "RestoreFilesInput": RestoreFilesInput,
  "RestoreRdsSubnetGroups": RestoreRdsSubnetGroups,
  "RestoreRdsSubnetGroupsRdsSubnetGroupsInner": RestoreRdsSubnetGroupsRdsSubnetGroupsInner,
  "RestoreSecurityGroups": RestoreSecurityGroups,
  "RestoreSecurityGroupsSecurityGroupsInner": RestoreSecurityGroupsSecurityGroupsInner,
  "RestoreVolumeInput": RestoreVolumeInput,
  "RestoreVolumeInputV1": RestoreVolumeInputV1,
  "RestoreVolumeInputVolumeSettings": RestoreVolumeInputVolumeSettings,
  "Role": Role,
  "RolesResponse": RolesResponse,
  "S3Bucket": S3Bucket,
  "S3Buckets": S3Buckets,
  "SamlIdentityProvider": SamlIdentityProvider,
  "SamlIdentityProviderConfigsResponse": SamlIdentityProviderConfigsResponse,
  "SchemaRecord": SchemaRecord,
  "SearchDBResponse": SearchDBResponse,
  "SearchFilesResponse": SearchFilesResponse,
  "SearchInput": SearchInput,
  "ServiceAccount": ServiceAccount,
  "ServiceAccountCredentials": ServiceAccountCredentials,
  "ServiceProviderDetails": ServiceProviderDetails,
  "SeverityFilters": SeverityFilters,
  "Snapshot": Snapshot,
  "SnapshotProperties": SnapshotProperties,
  "SnapshotPropertiesVolumePropertiesInner": SnapshotPropertiesVolumePropertiesInner,
  "SourceRegionFilters": SourceRegionFilters,
  "SourceStorageBackupStatus": SourceStorageBackupStatus,
  "SsoIntegrationSettings": SsoIntegrationSettings,
  "StatusCodeFilters": StatusCodeFilters,
  "StatusJobFilters": StatusJobFilters,
  "SubnetFilters": SubnetFilters,
  "TableRecord": TableRecord,
  "TokenResponse": TokenResponse,
  "UnifiedDBRecord": UnifiedDBRecord,
  "UnifiedDBServerRecord": UnifiedDBServerRecord,
  "UnifiedDatabaseRecord": UnifiedDatabaseRecord,
  "UnifiedSchemaRecord": UnifiedSchemaRecord,
  "UnifiedTableRecord": UnifiedTableRecord,
  "UpdateAccountInput": UpdateAccountInput,
  "UpdateBackupVaultNameInput": UpdateBackupVaultNameInput,
  "UpdateControlViolationInput": UpdateControlViolationInput,
  "UpdateDataClassificationsRequest": UpdateDataClassificationsRequest,
  "UpdateEnvironmentOverrideInput": UpdateEnvironmentOverrideInput,
  "UpdateResourceExclusionInput": UpdateResourceExclusionInput,
  "UpdateRoleInput": UpdateRoleInput,
  "UpdateSamlIdentityProviderInput": UpdateSamlIdentityProviderInput,
  "UpdateServiceAccountRequest": UpdateServiceAccountRequest,
  "UpdateUserInput": UpdateUserInput,
  "UpdateViewerRoleRequest": UpdateViewerRoleRequest,
  "User": User,
  "UserIdFilters": UserIdFilters,
  "VaultFilters": VaultFilters,
  "Viewer": Viewer,
  "VisualizationHistogramSeries": VisualizationHistogramSeries,
  "VisualizationHistogramTimePoint": VisualizationHistogramTimePoint,
  "VisualizationLabel": VisualizationLabel,
  "VolumeSettings": VolumeSettings,
  "VpcPropertyFilters": VpcPropertyFilters,
}

export class ObjectSerializer {
  public static findCorrectType(data: any, expectedType: string) {
    if (data == undefined) {
      return expectedType;
    } else if (primitives.indexOf(expectedType.toLowerCase()) !== -1) {
      return expectedType;
    } else if (expectedType === "Date") {
      return expectedType;
    } else {
      if (enumsMap.has(expectedType)) {
        return expectedType;
      }

      if (!typeMap[expectedType]) {
        return expectedType; // w/e we don't know the type
      }

      // Check the discriminator
      let discriminatorProperty = typeMap[expectedType].discriminator;
      if (discriminatorProperty == null) {
        return expectedType; // the type does not have a discriminator. use it.
      } else {
        if (data[discriminatorProperty]) {
          var discriminatorType = data[discriminatorProperty];
          if(typeMap[discriminatorType]){
            return discriminatorType; // use the type given in the discriminator
          } else {
            return expectedType; // discriminator did not map to a type
          }
        } else {
          return expectedType; // discriminator was not present (or an empty string)
        }
      }
    }
  }

  public static serialize(data: any, type: string, format: string) {
    if (data === undefined || data === null || primitives.includes(type.toLowerCase())) {
      return data;
    } else if (type.startsWith("Array<", 0)) { 
      let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
      subType = subType.substring(0, subType.length - 1); // Type> => Type
      let transformedData: any[] = [];
      for (let date of data) {
        transformedData.push(ObjectSerializer.serialize(date, subType, format));
      }
      return transformedData;
    } else if (type === "Date") {
      if (format == "date") {
        let month = data.getMonth()+1
        month = month < 10 ? "0" + month.toString() : month.toString()
        let day = data.getDate();
        day = day < 10 ? "0" + day.toString() : day.toString();

        return data.getFullYear() + "-" + month + "-" + day;
      } else {
        return data.toISOString();
      }
    } else {
      if (enumsMap.has(type)) {
        return data;
      }
      if (!typeMap[type]) { // in case we dont know the type
        return data;
      }

      // Get the actual type of this object
      type = this.findCorrectType(data, type);

      // get the map for the correct type.
      let attributeTypes = typeMap[type].getAttributeTypeMap();
      let instance: {[index: string]: any} = {};
      for (let attributeType of attributeTypes) {
        instance[attributeType.baseName] = ObjectSerializer.serialize(data[attributeType.name], attributeType.type, attributeType.format);
      }
      return instance;
    }
  }

  public static deserialize(data: any, type: string, format: string) {
    // polymorphism may change the actual type.
    type = ObjectSerializer.findCorrectType(data, type);
    if (data == undefined) {
      return data;
    } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
      return data;
    } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
      let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
      subType = subType.substring(0, subType.length - 1); // Type> => Type
      let transformedData: any[] = [];
      for (let date of data) {
        transformedData.push(ObjectSerializer.deserialize(date, subType, format));
      }
      return transformedData;
    } else if (type === "Date") {
      return new Date(data);
    } else {
      if (enumsMap.has(type)) {// is Enum
        return data;
      }

      if (!typeMap[type]) { // dont know the type
        return data;
      }
      let instance = new typeMap[type]();
      let attributeTypes = typeMap[type].getAttributeTypeMap();
      for (let attributeType of attributeTypes) {
        let value = ObjectSerializer.deserialize(data[attributeType.baseName], attributeType.type, attributeType.format);
        if (value !== undefined) {
          instance[attributeType.name] = value;
        }
      }
      return instance;
    }
  }

  /**
   * From a list of possible media types, choose the one we can handle best.
   *
   * The order of the given media types does not have any impact on the choice
   * made.
   */
  public static getPreferredMediaType(mediaTypes: Array<string>): string {
    return "application/json";
  }

}
