/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class OverrideEnvironmentInput {
  'environment'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "environment",
      "baseName": "environment",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return OverrideEnvironmentInput.attributeTypeMap;
  }

  public constructor() {
  }
}

