/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SortOrder } from '../models/SortOrder';

export class ListInventoryRequestSorts {
  'backupStatus'?: SortOrder;
  'accountId'?: SortOrder;
  'resourceName'?: SortOrder;
  'environment'?: SortOrder;
  'resourceId'?: SortOrder;
  'sourceStorageSize'?: SortOrder;
  'backupStorageSize'?: SortOrder;
  'eonSnapshotsCount'?: SortOrder;
  'genericSnapshotsCount'?: SortOrder;
  'lastBackup'?: SortOrder;
  'firstBackup'?: SortOrder;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "backupStatus",
      "baseName": "backupStatus",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "accountId",
      "baseName": "accountId",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "resourceName",
      "baseName": "resourceName",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "environment",
      "baseName": "environment",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "resourceId",
      "baseName": "resourceId",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "sourceStorageSize",
      "baseName": "sourceStorageSize",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "backupStorageSize",
      "baseName": "backupStorageSize",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "eonSnapshotsCount",
      "baseName": "eonSnapshotsCount",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "genericSnapshotsCount",
      "baseName": "genericSnapshotsCount",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "lastBackup",
      "baseName": "lastBackup",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "firstBackup",
      "baseName": "firstBackup",
      "type": "SortOrder",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListInventoryRequestSorts.attributeTypeMap;
  }

  public constructor() {
  }
}



