/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export enum BackupStatus {
  BackupPostureStatusUnspecified = 'BACKUP_POSTURE_STATUS_UNSPECIFIED',
  NotBackedUp = 'NOT_BACKED_UP',
  ViolationsDetected = 'VIOLATIONS_DETECTED',
  GenericBackups = 'GENERIC_BACKUPS',
  ExcludedFromBackup = 'EXCLUDED_FROM_BACKUP',
  AllViolationsMuted = 'ALL_VIOLATIONS_MUTED',
  Protected = 'PROTECTED',
  InitialClassification = 'INITIAL_CLASSIFICATION',
  Terminated = 'TERMINATED',
  Unsupported = 'UNSUPPORTED',
  Disconnected = 'DISCONNECTED'
}
