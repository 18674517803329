/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class OperationIdFilters {
  'contains'?: Array<string>;
  'notContains'?: Array<string>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "contains",
      "baseName": "Contains",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "notContains",
      "baseName": "NotContains",
      "type": "Array<string>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return OperationIdFilters.attributeTypeMap;
  }

  public constructor() {
  }
}

