/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { ActionFilters } from '../models/ActionFilters';
import { OperationIdFilters } from '../models/OperationIdFilters';
import { StatusCodeFilters } from '../models/StatusCodeFilters';
import { UserIdFilters } from '../models/UserIdFilters';

export class AuditLogFilterConditions {
  'action'?: ActionFilters;
  'operationId'?: OperationIdFilters;
  'userId'?: UserIdFilters;
  'statusCode'?: StatusCodeFilters;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "action",
      "baseName": "action",
      "type": "ActionFilters",
      "format": ""
    },
    {
      "name": "operationId",
      "baseName": "operationId",
      "type": "OperationIdFilters",
      "format": ""
    },
    {
      "name": "userId",
      "baseName": "userId",
      "type": "UserIdFilters",
      "format": ""
    },
    {
      "name": "statusCode",
      "baseName": "statusCode",
      "type": "StatusCodeFilters",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AuditLogFilterConditions.attributeTypeMap;
  }

  public constructor() {
  }
}

