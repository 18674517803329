/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SortOrder } from '../models/SortOrder';

export class ListJobsRequestSorts {
  'id'?: SortOrder;
  'status'?: SortOrder;
  'startedAt'?: SortOrder;
  'userId'?: SortOrder;
  'updatedAt'?: SortOrder;
  'onDemand'?: SortOrder;
  'resourceId'?: SortOrder;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "status",
      "baseName": "status",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "startedAt",
      "baseName": "startedAt",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "userId",
      "baseName": "userId",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "updatedAt",
      "baseName": "updatedAt",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "onDemand",
      "baseName": "onDemand",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "resourceId",
      "baseName": "resourceId",
      "type": "SortOrder",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListJobsRequestSorts.attributeTypeMap;
  }

  public constructor() {
  }
}



