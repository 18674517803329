/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { InventoryFilterConditions } from '../models/InventoryFilterConditions';
import { ListInventoryRequestSorts } from '../models/ListInventoryRequestSorts';
import { QueryParams } from '../models/QueryParams';

export class ListInventoryRequest {
  'queryParams'?: QueryParams;
  'sorts'?: ListInventoryRequestSorts;
  'filters'?: InventoryFilterConditions;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "queryParams",
      "baseName": "queryParams",
      "type": "QueryParams",
      "format": ""
    },
    {
      "name": "sorts",
      "baseName": "sorts",
      "type": "ListInventoryRequestSorts",
      "format": ""
    },
    {
      "name": "filters",
      "baseName": "filters",
      "type": "InventoryFilterConditions",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListInventoryRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

