/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class ActionFilters {
  '_in'?: Array<string>;
  'notIn'?: Array<string>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "_in",
      "baseName": "In",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "notIn",
      "baseName": "NotIn",
      "type": "Array<string>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ActionFilters.attributeTypeMap;
  }

  public constructor() {
  }
}

