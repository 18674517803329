/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SortOrder } from '../models/SortOrder';

export class ListAuditLogRequestSorts {
  'action'?: SortOrder;
  'operationId'?: SortOrder;
  'requestTime'?: SortOrder;
  'statusCode'?: SortOrder;
  'userId'?: SortOrder;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "action",
      "baseName": "action",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "operationId",
      "baseName": "operationId",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "requestTime",
      "baseName": "requestTime",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "statusCode",
      "baseName": "statusCode",
      "type": "SortOrder",
      "format": ""
    },
    {
      "name": "userId",
      "baseName": "userId",
      "type": "SortOrder",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListAuditLogRequestSorts.attributeTypeMap;
  }

  public constructor() {
  }
}



